export const setUpScrollHandler = (handler: () => void) => {
  const scrollEndHandler = () => {
    handler();
    window.removeEventListener('scrollend', scrollEndHandler);
  };

  // we need to listen to the scrollend event
  // because the stepper component scrolls content to the top
  // on render. So we need to wait until that is over before
  // we can calculate the position of other elements
  window.addEventListener('scrollend', scrollEndHandler);
};
